import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { gql, useApolloClient } from '@apollo/client';

import Link from 'components/Link';
import IconAlert from 'images/icons/Warning';
import PopupError from './PopupError';

const ErrorMessage = styled.div`
	text-align: left;
	padding: 0;
	font-weight: 500;
	margin: 0;
	background: transparent;
	border-radius: ${p => p.theme.utils.borderRadius};
	display: flex !important;
	align-items: flex-start;
	span,
	a {
		color: ${p => p.theme.colors.coral900};
		margin-bottom: 0 !important;
	}
	a:hover {
		color: ${p => p.theme.colors.black};
	}
	${p =>
		p.centered &&
		css`
			width: fit-content;
			margin-left: auto;
			margin-right: auto;
			${p =>
				p.theme.media.medium(css`
					text-align: center;
				`)}
		`}
	${p =>
		p.bg &&
		css`
			padding: 14.5px 15px;
			background: ${p => p.theme.colors.coral200};
			color: ${p => p.theme.colors.coral800};
			${p =>
				p.theme.media.large(css`
					align-items: center;
				`)}
			svg {
				width: 17px;
				height: 26px;
				${p =>
					p.theme.media.smallOnly(css`
						height: 21px;
					`)}
			}
		`}
`;

const Icon = styled.span`
	margin-right: 10px;
	text-align: left;
	svg {
		width: 22px;
		height: 26px;
		text-align: left;
		display: block;
		color: ${p => p.theme.colors.coral800};
	}
`;

/**
 * Error message component with icon and text, and a link to customer service chat or a link to a popup with a lead form
 * @param {Object} props
 * @param {String} props.text - The text to display in the error message (optional)
 * @param {Boolean} props.centered - Whether the error message should be centered or not, default is false
 * @param {Boolean} props.bg - Whether the error message should have a background or not, default is true
 * @param {String} props.resolver - The resolver that caused the error (optional)
 * @param {String} props.errorMessage - The error message (optional)
 * @param {String} props.url - The url where the error occurred (optional)
 * @param {Boolean} props.popup - Whether the error message should be displayed in a popup or not (optional)
 * @param {Object} props - Other props
 * @returns {JSX.Element}
 */
export default function Error({
	text = '',
	centered = false,
	bg = true,
	resolver = '',
	errorMessage = '',
	url = '',
	popup,
	...props
}) {
	const client = useApolloClient();

	async function slackAlert() {
		try {
			const { data, loading, error } = await client.mutate({
				mutation: gql`
					mutation slackAlert(
						$resolver: String!
						$error: String!
						$url: String!
					) {
						slackAlert(
							resolver: $resolver
							error: $error
							url: $url
						)
					}
				`,
				variables: {
					resolver,
					error: errorMessage,
					url: url || window?.location?.href,
				},
				skip: !resolver || !errorMessage,
			});

			if (!loading && error) throw new Error(error);

			return data?.slackAlert;
		} catch {
			console.error('Error in slackAlert');
			return false;
		}
	}

	useEffect(() => {
		if (!resolver || !errorMessage) return;
		slackAlert();
		// eslint-disable-next-line
	}, [resolver, errorMessage]);

	return (
		<ErrorMessage bg={bg} centered={centered} {...props}>
			<Icon>
				<IconAlert
					alt="Ikon som indikerer en feil"
					type={bg ? 'regular' : 'solid'}
				/>
			</Icon>

			{(popup && (
				<PopupError
					type="text"
					text={text ? `${text}.` : 'Her skjedde det noe galt.'}
					form={popup}
					{...props}
				/>
			)) || (
				<span>
					<>
						{text ? `${text}. ` : 'Her skjedde det noe galt. '}
						<Link
							to="/kundeservice/chat/"
							title="Ta kontakt med oss">
							Ta kontakt med oss så finner vi ut av det sammen.
						</Link>{' '}
					</>
				</span>
			)}
		</ErrorMessage>
	);
}
